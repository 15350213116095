// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { Store } from 'vuex';
import { Route } from 'vue-router';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useDynamicyieldStore } from '~/@api/store/dynamicyieldApi';
import { clientInitLoadingStore, useLoadingStore } from '~/@api/store/loadingApi';
import { clientInitRoutingStore, useRoutingStore } from '~/@api/store/routingApi';
import { useSearchStore } from '~/@api/store/searchApi';
import { JsonResponse } from '~/app-utils/http';
import { SpaLink, SpaLinkRel, SpaMetaInformation, SpaOtherId } from '~/generated/hybris-raml-api';
import { PageTypes, RootState, RoutingState } from '~/@api/store.types';
import {
	importElapsedTime,
	importFollowRedirectIfGiven,
	importLodashCeil,
	importMiddlewareSearch,
	importRunTask,
	importSearchUtils,
	importSpaUtils,
} from '~/app-utils/dynamic-imports';

export default async (context: Context) => {
	if (process.client) {
		await Promise.all([
			clientInitLoadingStore(context.store),
			clientInitRoutingStore(context.store),
		]);
	}

	const { getPageTypeMetaInfoFromRoute } = await importSpaUtils();
	const spaKey = getPageTypeMetaInfoFromRoute(context.store, context.route);

	if (process.server || (context.from.name !== null && context.from.path !== '/')) {
		const { api: loadingApi } = useLoadingStore(context.store);
		const { api: routingApi } = useRoutingStore(context.store);

		await loadingApi.setLoading('updateSPAData', true);

		const dynamicImportResponses = await Promise.all([
			importElapsedTime(),
			importMiddlewareSearch(),
		]);

		const elapsedTime = dynamicImportResponses[0].elapsedTime;
		const isSearchPage = dynamicImportResponses[1].isSearchPage;
		const isPaginationRequest = dynamicImportResponses[1].isPaginationRequest;

		if (!isSearchPage(context) || !isPaginationRequest(context)) {
			const { followRedirectIfGiven } = await importFollowRedirectIfGiven();

			useDynamicyieldStore(context.store).api.setPath(context.route.fullPath);

			await Promise.all<boolean | void | number>([
				routingApi
					.updateSPAData(spaKey.spaType, spaKey.identifier)
					.then((response) => setResponseStatusCode(response, context)),
				spaKey.identifier &&
				// cms contentupdate for searchpages is done within search-middleware
				!spaKey.identifier.startsWith(SpaOtherId.search) &&
				!spaKey.identifier.startsWith(SpaOtherId.searchEmpty)
					? useCmsContentStore(context.store)
							.api.update(
								spaKey.spaType,
								spaKey.identifier,
								false,
								context.route.fullPath,
								context.route.matched,
							)
							.then((response) => followRedirectIfGiven(response, context))
					: Promise.resolve(),
			]);
		} else {
			await routingApi
				.updateSPAData(spaKey.spaType, spaKey.identifier)
				.then((response) => setResponseStatusCode(response, context));
		}
		// we don't need to await this request for rendering the page
		routingApi.storeI18NUrls(spaKey.spaType, spaKey.identifier);
		updateSPADataForSearch(context);

		await loadingApi.setLoading('updateSPAData', false);
		elapsedTime('middleware: update-spa-data');
	}
};

const setResponseStatusCode = async (response: JsonResponse, context: Context) => {
	if (process.server) {
		const { isCategoryOrBrandPage } = await importMiddlewareSearch();
		if (
			isCategoryOrBrandPage(context) &&
			useSearchStore(context.store).state.response?.pagination?.totalCount === 0 // override status with 404 if there are no products on category/brand page (old category or brand)
		) {
			context.res.statusCode = 404;
		} else {
			context.res.statusCode = response.status;
		}
	}
};

interface IUpdateSPADataContext {
	route: Route;
	store: Store<any>;
}

export const updateSPADataForSearch = async (context: IUpdateSPADataContext) => {
	const { state: searchState } = useSearchStore(context.store);
	const { api: routingApi, state: routingState } = useRoutingStore(context.store);

	if (
		![PageTypes.PRODUCTSEARCH, PageTypes.CATEGORY, PageTypes.BRAND].includes(
			(Array.isArray(context.route.meta)
				? [...context.route.meta].reverse()
				: [context.route.meta]
			).find((meta) => meta?.pageType)?.pageType,
		)
	) {
		return Promise.resolve();
	}

	const spaData = routingState.spaData;
	const { query } = context.route;
	const page: number = process.server
		? parseInt((context.route.query.page as string) || '1', 10)
		: searchState.response.pagination.page || 1;

	let meta = [...spaData.meta];

	// If no full title is present, add it before any other modification
	if (!meta.some((metaItem) => metaItem.property === 'osp:full-title')) {
		meta.push({ content: spaData.title, property: 'osp:full-title' });
	}

	// If no full description is present, add it before any other modification
	if (!meta.some((metaItem) => metaItem.property === 'osp:full-description')) {
		meta.push({
			content: meta.find((metaItem) => metaItem.name === 'description')?.content,
			property: 'osp:full-description',
		});
	}

	// Don't index category pages with only 1 product:
	// Also don't index searchpages with q- or text query parameter:
	if (searchState.response?.pagination?.totalCount < 2 || query.q || query.text) {
		meta = meta.filter((meta) => meta.name !== 'robots');
		meta.push({ content: 'noindex,follow', name: 'robots' });
	}

	// Grab the raw full title and description (without any pagination addition or cut offs)
	const fullTitle = meta.find((metaItem) => metaItem.property === 'osp:full-title')?.content;
	const fullDescription = meta.find((metaItem) => metaItem.property === 'osp:full-description')
		?.content;

	// Generate seo conform meta title
	const metaTitle = updatePaginationInfoInMetaContent(
		context.store.state as RootState,
		fullTitle,
		page,
		60,
	);

	// Generate seo conform meta description
	const metaDescription = updatePaginationInfoInMetaContent(
		context.store.state as RootState,
		fullDescription,
		page,
		155,
	);

	// Go through all meta information and update all titles and descriptions
	meta = updateMetaTitlesAndDescriptions(meta, metaTitle, metaDescription);

	// Add meta information if necessary
	if (!meta.some((meta) => meta.name === 'description')) {
		meta.push({ name: 'description', content: metaDescription });
	}

	// Save the current meta and link information
	importRunTask().then(({ runTask }) => {
		runTask(async () => {
			routingApi.saveSPAData({
				metaInformation: {
					links: await generateHeaderLinks(context, spaData),
					metaInformations: meta,
					title: metaTitle,
				},
				richSnippet: spaData.richSnippet ? JSON.parse(spaData.richSnippet) : null,
			});
		});
	});
};

function updateMetaTitlesAndDescriptions(
	meta: SpaMetaInformation[],
	metaTitle: string,
	metaDescription: string,
) {
	return meta.map(({ ...meta }) => {
		if (meta.name === 'title' || meta.property?.includes(':title')) {
			meta.content = metaTitle;
		} else if (meta.name === 'description' || meta.property?.includes(':description')) {
			meta.content = metaDescription;
		}

		return meta;
	});
}

async function generateHeaderLinks(
	context: IUpdateSPADataContext,
	spaData: RoutingState['spaData'],
): Promise<SpaLink[]> {
	const { state: searchState } = useSearchStore(context.store);
	const dynamicImportResponses = await Promise.all([importLodashCeil(), importSearchUtils()]);

	const { createSearchUrl } = dynamicImportResponses[1];

	// Generate a seo conform search url
	const seo = createSearchUrl(context.store, context.route, searchState.response, true, true);

	let links = [...spaData.links];

	// Go through all previously existing link information and update
	links = links.map(({ ...link }) => {
		if (link.rel === SpaLinkRel.canonical) {
			link.href = seo;
		}

		return link;
	});

	const isNoIndex = spaData.meta.some(
		(meta) => meta.name === 'robots' && meta.content.startsWith('noindex'),
	);
	// Add link information if necessary
	if (!links.some((link) => link.rel === SpaLinkRel.canonical) && !isNoIndex) {
		links.push({ rel: SpaLinkRel.canonical, href: seo });
	}

	return links;
}

const updatePaginationInfoInMetaContent = (
	state: RootState,
	content: string,
	pageNumber: number | undefined,
	maxLength: number | undefined = undefined,
) => {
	// Only provide pagination information starting at 2nd page
	if (pageNumber <= 1) {
		pageNumber = undefined;
	}

	const langIsoCode = state.user?.user?.language?.isocode || 'de';
	const paginationFragment = state.i18n.messages[langIsoCode]['v2.paginationNavigation.metaSuffix'];
	const pattern = new RegExp(paginationFragment?.replace('{number}', '(\\d+)'));

	// Remove pagination info from content
	let newContent = content?.replace(pattern, '');

	// No pagination information or first page -> return newContent
	if (typeof pageNumber === 'undefined') {
		return newContent;
	}

	const newPaginationInfo = paginationFragment?.replace('{number}', pageNumber);

	// If newContent should be limited to a max length, check if enough space for pagination info would be possible
	// otherwise cut of newContent
	if (maxLength) {
		if (newContent.length > maxLength - newPaginationInfo.length) {
			newContent = newContent.slice(0, maxLength - newPaginationInfo.length - 3) + '...';
		}
	}

	// Add current pagination info as postfix to newContent and return info
	return newContent + newPaginationInfo;
};
