import _omit from 'lodash-es/omit';
import type { Store } from 'vuex';
import { Route } from 'vue-router';
import slugify from '@/node_modules/@osp/utils/src/slugify';
import { getQueryParameters } from '@/node_modules/@osp/utils/src/url-parameter-parser';
import { updateUrlParam } from '@/node_modules/@osp/design-system/assets/js/utilities/url';
import { yieldToMain } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import {
	SearchFacet,
	SearchFacetValueCategories,
	SearchFacetValuePrice,
	SearchPagination,
	SearchRequestResponse,
	SearchSorts,
} from '@/generated/hybris-raml-api';
import { url } from '@/@api/backend';
import { useSearchStore } from '~/@api/store/searchApi';
import { useUserStore } from '~/@api/store/userApi';
import { CategoryList, RootState } from '~/@api/store.types';

interface SimplifiedSearchFacetValueCategories {
	code: string;
	name: string;
	subCategories: unknown[];
}

export const getSelectedFacets = (
	facets: SearchFacet[],
	target: SearchFacet[] | { [code: string]: SearchFacet },
): SearchFacet[] | { [code: string]: SearchFacet } => {
	const isArray = Array.isArray(target);

	facets
		.filter((facet) => !!facet)
		.forEach(async (facet) => {
			const omitted = _omit(facet, 'values') as SearchFacet;

			omitted.values =
				facet.code === 'navigation'
					? [getSelectedNavigationValue(facet).pop()]?.filter((value) => value)
					: (facet.values || []).filter((value: any) => value.selected);

			// Only facets with values
			if (omitted.values.length > 0) {
				if (isArray) {
					(target as SearchFacet[]).push(omitted);
				} else {
					target[omitted.code] = omitted;
				}
			}

			await yieldToMain();
		});

	return target;
};

export const getSelectedNavigationValue = (facet: SearchFacet) => {
	const values = [];
	const recursiveConsumer: (value: SearchFacetValueCategories) => boolean = (
		value: SearchFacetValueCategories,
	) => {
		if (value.selected) {
			values.unshift(_omit(value, 'subCategories'));

			return true;
		} else {
			let found = false;

			(value.subCategories || []).forEach((subValue) => {
				if (recursiveConsumer(subValue)) {
					found = true;

					values.unshift(_omit(value, 'subCategories'));
				}
			});

			return found;
		}
	};

	(facet.values || []).forEach(recursiveConsumer);

	return values;
};

export const createSearchUrl = (
	store: Store<RootState>,
	currentRoute: Route,
	request: SearchRequestResponse,
	seoOptimized = false,
	seoWithPage = false,
	selectedCategoryCode?: string,
): string => {
	const { state: searchState } = useSearchStore(store);

	// Calculate gender
	const genderLabel = getGender(store, request);

	// Get category attributes
	const { categoryCode, categoryName, categoryPath, landingPage } = getSearchUrlCategoryAttributes(
		selectedCategoryCode,
		searchState.categories,
		request.facets,
		searchState.response.facets,
		searchState.response.categoryCode,
		store,
	);

	// Generate filename
	const filename = getSearchUrlFilename(
		genderLabel,
		categoryCode,
		categoryName,
		categoryPath,
		landingPage,
		request.brandCategoryCode,
	);

	// Query string
	if (!seoOptimized) {
		const selectedFacets = getSelectedFacets(request.facets || [], []) as SearchFacet[];
		const queryNeedsParams = queryStringNeedsParams(
			selectedFacets,
			request.pagination,
			request.sorts,
			request.text,
		);

		if (queryNeedsParams) {
			return getSearchUrlWithParams(store, filename, selectedFacets, request, currentRoute);
		} else {
			return url(
				store,
				`/${filename}${request.pagination.page !== 1 ? '?page=' + request.pagination.page : ''}`,
			);
		}
	} else if (seoWithPage) {
		return getSeoSearchUrl(store, filename, request.pagination.page);
	} else {
		return url(store, `/${filename}${request.text ? '?text=' + request.text : ''}`);
	}
};

function getSeoSearchUrl(
	store: Store<RootState>,
	filename: string,
	paginationPage: number,
	requestText?: string,
): string {
	const requestTextUrlParam = requestText ? `text=${requestText}` : false;
	const pageParam = paginationPage !== 1 ? `page=${paginationPage}` : false;
	const urlParams = [requestTextUrlParam, pageParam].filter((param) => param !== false).join('&');
	const urlParamString = urlParams ? `?${urlParams}` : '';

	return url(store, `/${filename}${urlParamString}`);
}

function getSearchUrlWithParams(
	store: Store<RootState>,
	filename: string,
	selectedFacets: SearchFacet[],
	request: SearchRequestResponse,
	currentRoute: Route,
): string {
	const queryStringFacets = getQueryStringFacets(selectedFacets);
	const queryStringCurrentNavigation =
		request.currentNavigation && request.currentNavigation.code
			? `&currentNavigation=${request.currentNavigation.code}`
			: '';
	const queryStringParameters = getQueryParameters(currentRoute.fullPath);
	let queryString = `?q=${request?.text ?? ''}:${
		request.sorts.selectedOption
	}:${queryStringFacets}${queryStringCurrentNavigation}&sort=${
		request.sorts.selectedOption
	}&pageSize=${request.pagination.selectedOption}&page=${request.pagination.page}`;

	queryString =
		queryString +
		(queryStringParameters.lp !== null && typeof queryStringParameters.lp?.valueOf() === 'string'
			? `&lp=${encodeURIComponent(queryStringParameters.lp)}`
			: '');

	return url(store, `/${filename}${queryString}`);
}

function queryStringNeedsParams(
	selectedFacets: SearchFacet[],
	pagination?: SearchPagination,
	sorts?: SearchSorts,
	requestText?: string,
): boolean {
	const defaultPageSize = 48;
	const defaultSort = 'null:desc';
	const filteredFacets = removeNavigationAndGenderFromFacets(selectedFacets);

	return (
		filteredFacets.length > 0 ||
		pagination?.selectedOption !== defaultPageSize ||
		(sorts && sorts.selectedOption !== defaultSort) ||
		!!requestText
	);
}

function removeNavigationAndGenderFromFacets(facets: SearchFacet[]): SearchFacet[] {
	return facets.filter((facet) => facet.code !== 'navigation' && facet.code !== 'gender');
}

function getSearchUrlCategoryAttributes(
	selectedCategoryCode: string,
	searchCategories: CategoryList,
	requestFacets: SearchFacet[],
	responseFacets: SearchFacet[],
	responseCategoryCode: string,
	store: Store<RootState>,
): {
	categoryCode: string;
	categoryName: string;
	categoryPath: string;
	landingPage: boolean;
} {
	const navigationFilter = requestFacets.find((facet) => facet.code === 'navigation');
	let categoryPath, categoryName, categoryCode;
	let landingPage = false;

	if (navigationFilter) {
		const targetCategoryCode =
			selectedCategoryCode ||
			navigationFilter.values.map(recursiveSelectedValueFinder).find((value) => value)?.code ||
			responseCategoryCode;
		let currentCategoryName;

		if (targetCategoryCode) {
			let categoryInfo = searchCategories[targetCategoryCode];

			if (!categoryInfo) {
				const searchStateCategories = useSearchStore(store).state.categories;
				categoryInfo = Object.values(searchStateCategories).find(
					(category) => category.code === targetCategoryCode,
				);
			}

			categoryCode = targetCategoryCode.split('/').reverse()[0];

			if (categoryInfo) {
				categoryCode = categoryInfo.code;
				categoryPath = categoryInfo.path;
				categoryName = categoryInfo.name;
				landingPage = categoryInfo.landingPage;
			} else {
				// Calculate the category information if it is not provided by the backend (cache not valid)
				// This should happen for new categories only
				const stateNavigationFilter = responseFacets.find((facet) => facet.code === 'navigation');

				if (stateNavigationFilter) {
					currentCategoryName = getCategoryNameRecursively(
						stateNavigationFilter,
						targetCategoryCode,
					);
				}

				categoryPath = [currentCategoryName, categoryCode]
					.filter((value) => value !== undefined)
					.join('-');
			}
		}
	}

	return {
		categoryCode,
		categoryName,
		categoryPath,
		landingPage,
	};
}

function getCategoryNameRecursively(
	stateNavigationFilter: SearchFacet,
	category,
): string | undefined {
	let currentCategoryName;

	(stateNavigationFilter.values || []).forEach((value: SimplifiedSearchFacetValueCategories) => {
		const recursiveResult = recursiveValueFinder(value, category);

		if (recursiveResult.found) {
			currentCategoryName = recursiveResult.currentCategoryName;
		}
	});

	return currentCategoryName;
}

function getSearchUrlFilename(
	genderLabel: string,
	categoryCode: string,
	categoryName: string,
	categoryPath: string,
	landingPage: boolean,
	brandCategoryCode?: string,
) {
	let filename;

	if (brandCategoryCode) {
		filename = buildBrandFilename(genderLabel, brandCategoryCode, categoryCode, categoryName);
	} else if (categoryPath) {
		filename = buildCategoryFilename(landingPage, genderLabel, categoryPath);
	} else {
		filename = 'search';
	}

	// Cleanup filename
	return slugify(filename, true, /[^a-z0-9\-_.]+/g);
}

function buildCategoryFilename(landingPage: boolean, genderLabel: string, categoryPath: string) {
	let filename = '';

	if (landingPage) {
		filename += 'lp/';
	}

	if (genderLabel) {
		filename += genderLabel;
	}

	return filename + categoryPath + '-c.html';
}

function buildBrandFilename(
	genderLabel: string,
	brandCategoryCode: string,
	categoryCode?: string,
	categoryName?: string,
): string {
	let filename = '';

	if (genderLabel && genderLabel !== 'Alle-') {
		filename += genderLabel;
	}

	filename += brandCategoryCode;

	if (categoryCode) {
		if (categoryName) {
			filename += '-' + categoryName;
		}

		if (categoryCode !== brandCategoryCode) {
			filename += '-' + categoryCode;
		}

		filename += '-b.html';
	} else {
		filename += '-shop.html';
	}

	return filename;
}

const recursiveValueFinder = (
	value: SimplifiedSearchFacetValueCategories,
	categoryCode: string,
) => {
	const result = {
		categoryName: undefined,
		currentCategoryName: undefined,
		found: false,
	};

	if (value?.code === categoryCode) {
		result.categoryName = value.name;
		result.currentCategoryName = value.name;
		result.found = true;
	} else {
		result.found = false;

		(value?.subCategories || []).forEach((subCategory: SimplifiedSearchFacetValueCategories) => {
			const recursiveResult = recursiveValueFinder(subCategory, categoryCode);

			if (recursiveResult.found) {
				result.currentCategoryName = value.name + '-' + recursiveResult.currentCategoryName;
				result.found = true;
			}
		});
	}

	return result;
};

const getQueryStringFacets = (selectedFacets) => {
	return selectedFacets
		.filter((facet) => facet.values.length > 0)
		.map((facet) => mapQueryStringFacets(facet))
		.filter((item) => !!item)
		.join(':');
};

const mapQueryStringFacets = (facet: SearchFacet): string => {
	if (facet.code === 'priceValue') {
		return `${facet.code}:${encodeURIComponent(
			(facet.values[0] as SearchFacetValuePrice).selectedMin +
				' - ' +
				(facet.values[0] as SearchFacetValuePrice).selectedMax,
		)}`;
	}

	return facet.values
		.map((value) => `${facet.code}:${encodeURIComponent((value as SearchFacet)?.code)}`)
		.join(':');
};

const recursiveSelectedValueFinder = (value) => {
	if (value.selected) {
		return value;
	} else {
		for (const subCategory of value.subCategories || []) {
			const selectedValue = recursiveSelectedValueFinder(subCategory);

			if (selectedValue) {
				return selectedValue;
			}
		}

		return undefined;
	}
};

export const setPageForSearchURL = (url: string, page: number | undefined) => {
	return updateUrlParam(url, 'page', page > 1 ? page.toString() : undefined).replace(
		/https?:\/\/[^/]*/,
		'',
	);
};

const getGender = (store: Store<RootState>, request: SearchRequestResponse) => {
	const { state: userState } = useUserStore(store);
	// Current gender from session
	const currentGender =
		userState.user?.gender?.code === 'NEUTRAL' ? 'neutral' : userState.user?.gender?.name;

	// Requested gender for search
	const requestedGender = (
		(request.facets
			.find((facet) => facet?.code === 'gender')
			?.values.find((facetValue: any) => facetValue.selected) as any) ?? {}
	)?.code;

	// If requested gender is NEUTRAL use "neutral"
	let requestedGenderLabel = requestedGender === 'NEUTRAL' ? 'neutral' : undefined;

	// Otherwise get localized gender from userState
	if (!requestedGenderLabel) {
		requestedGenderLabel = requestedGender
			? userState.user?.genders?.find((gender) => gender.code === requestedGender)?.name
			: null;
	}

	// Calculate gender
	const genderLabel = requestedGenderLabel || currentGender || 'neutral';

	return request.brandCategoryCode && genderLabel === 'neutral' ? null : `${genderLabel}-`;
};
