import { ImageOptimizationSettings } from '@/node_modules/@osp/design-system/components/Media/Media.props';
import { UspBarModel } from '@/node_modules/@osp/design-system/components/UspBar/UspBar.props';
import { BrandListModel } from '@/node_modules/@osp/design-system/components/BrandList/BrandList.props';
import { CampaignTeaserModel } from '@/node_modules/@osp/design-system/components/CampaignTeaser/CampaignTeaser.props';
import { ImageTileModel } from '@/node_modules/@osp/design-system/components/ImageTile/ImageTile.props';
import { ImageTileWithDescriptionModel } from '@/node_modules/@osp/design-system/components/ImageTileWithDescription/ImageTileWithDescription.props';
import { PromotionTeaserModel } from '@/node_modules/@osp/design-system/components/PromotionTeaser/PromotionTeaser.props';
import { TestimonialModel } from '@/node_modules/@osp/design-system/components/Testimonial/Testimonial.props';
import { RichTextModel } from '@/node_modules/@osp/design-system/components/RichText/RichText.props';
import { SliderModel } from '@/node_modules/@osp/design-system/components/Slider/Slider.props';
import { ListingItem } from '@/node_modules/@osp/design-system/types/listing';
import { DebugSettings } from '@/node_modules/@osp/design-system/components/mixins/cls-base-mixin';
import { SectionLayoutDataListModel } from '~/components/section-layout/section-layout__data-list.props';
import { SectionLayoutModel } from '~/components/section-layout/section-layout.props';
import { Autocomplete } from '~/app-utils/search.suggestion';
import { DYProductRecommendationModel } from '~/components/dynamic-yield/dy-product-recommendation/dy-product-recommendation.props';
import {
	AddressList,
	Bar,
	Brand,
	BrandTeaserComponent,
	Breadcrumb,
	Campaign,
	CampaignTeaserComponent,
	Cart,
	CategorySliderComponent,
	CheckoutStep,
	ClubInfo,
	CommunicationCenter,
	ContentNavigationNode,
	CurrentNavigation,
	CustomerGiftCard,
	DYProductRecommendationComponent,
	ForgottenPaswordResponse,
	FormConfiguration,
	Gender,
	GlobalMessage,
	GridLayout,
	GuestRegistrationResponse,
	Image,
	ImageTeaserComponent,
	InstallmentCondition,
	LoginResponse,
	MapEntry,
	Order,
	PaymentMode,
	PointOfService,
	Product,
	ProductLabel,
	ProductPrice,
	PromotionTeaserComponent,
	PromotionTypeSettings,
	RegistrationResponse,
	SearchFacet,
	SearchPagination,
	SearchSorts,
	Serversetting,
	SpaDigitalDatum,
	SpaLink,
	SpaMetaInformation,
	TeaserDataList,
	TestimonialTeaserComponent,
	TextComponent,
	Trusty,
	User,
	ValidationErrorResponse,
} from '~/generated/hybris-raml-api';

// ---------------------------------------------------------------------------
// Addressbook module
// ---------------------------------------------------------------------------
export type AddressBookState = AddressList;

// ---------------------------------------------------------------------------
// Cart module
// ---------------------------------------------------------------------------
export enum CartStatusMessage {
	MAX_ORDER_QUANTITY_EXCEEDED = 'MAX_ORDER_QUANTITY_EXCEEDED',
	SUCCESS = 'SUCCESS',
	LOW_STOCK = 'LOW_STOCK',
	NO_STOCK = 'NO_STOCK',
	PRODUCT_UNAVAILABLE = 'PRODUCT_UNAVAILABLE',
	CART_LIMIT_EXCEEDED = 'CART_LIMIT_EXCEEDED',
	UNAVAILABLE = 'UNAVAILABLE',
	SUCCESSFULLY_REMOVED = 'SUCCESSFULLY_REMOVED',
	UNKOWN = 'UNKOWN',
}

export interface CartState {
	cart: Cart;
	installmentCondition: InstallmentCondition;
	modificationStatus: CartStatusMessage;
	trusties: Trusty[];
}

// --------------------------------------------------------------------------
// Product
// --------------------------------------------------------------------------
export interface CmsProductImage {
	altText: string;
	assetId: string;
	format: string;
	imageType: string;
	title: string;
	url: string;
}

export interface CmsProductData {
	averageRating: number;
	aspectRatio: { width: 16; height: 9 };
	brand: {
		image: CmsProductImage;
		name: string;
		url: string;
	};
	crossPrice: {
		formatedValue: string;
		currency: string;
		value: number;
	};
	images: CmsProductImage[];
	name: string;
	newProduct: boolean;
	numberOfReviews: number;
	price: {
		currencyIso: string;
		formattedValue: string;
		priceType: string;
		value: number;
	};
	sale: boolean;
}

// --------------------------------------------------------------------------
// Teaser Expiring Shortage
// --------------------------------------------------------------------------
export interface CmsTeaserExpiringShortageData {
	startTime: number;
	endTime: number;
}

// ---------------------------------------------------------------------------
// DynamicYield module
// ---------------------------------------------------------------------------
export interface DynamicYieldCredentials {
	_dyid_server: string;
	_dyid: string;
	_dyjsession: string;
}

export interface DynamicYieldCookie {
	name: string;
	value: string;
	maxAge: string;
}

export interface DynamicYieldData {
	[id: string]: any;
}

export interface DynamicYieldChoice {
	id: number;
	name: string;
	type: string;
	variations: any[];
	decisionId: string | null;
}

export interface DynamicYieldState {
	isInitializing: boolean;
	credentials: DynamicYieldCredentials;
	data: DynamicYieldData;
	choices: { [k: string]: DynamicYieldChoice };
	fetching: string[];
	path: string;
	config: {
		debugging: DebugSettings;
	};
}

export interface DynamicYieldPayload {
	selector: string | string[];
	pageContext: any;
	forceUpdate?: boolean;
	waitForChoice?: boolean;
}

export interface DynamicYieldVariation {
	id: number;
	payload: any;
}

// ---------------------------------------------------------------------------
// Checkout module
// ---------------------------------------------------------------------------
export interface PaymentInfo {
	key: string;
	value: any;
}

export interface PaymentState {
	iframeUrl: string;
	infos: PaymentInfo[];
	paymentModes: PaymentMode[];
}

export interface CheckoutModuleState {
	allowedSteps: CheckoutStep[];
	currentStep: CheckoutStep;
	nextStep: CheckoutStep;
	payment: PaymentState;
}

// ---------------------------------------------------------------------------
// Forms module
// ---------------------------------------------------------------------------
export interface FormModel {
	[fieldId: string]: any;
}

export interface TypeAheadData {
	label: string;
	model: FormModel;
}

export interface InputHandler {
	typeAhead: (model: FormModel) => TypeAheadData[];
}

export interface FormData {
	config: FormConfiguration;
	validationResponse: ValidationErrorResponse;
	inputHandler: {
		[fieldId: string]: InputHandler;
	};
}

export interface FormsState {
	forms: {
		[code: string]: FormData;
	};
}

// ---------------------------------------------------------------------------
// I18n module
// ---------------------------------------------------------------------------
export interface I18NModuleState {
	locale: string;
	fallbackLocale: string;
	messages: any;
}

// ---------------------------------------------------------------------------
// Installprompt module
// ---------------------------------------------------------------------------
export type InstallPromptUserChoice = 'accepted' | 'dismissed';

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 * @notice Only supported on Chrome and Android Webview.
 */
export interface BeforeInstallPromptEvent extends Event {
	/**
	 * Returns an array of DOMString items containing the platforms on which the event was dispatched.
	 * This is provided for user agents that want to present a choice of versions to the user such as,
	 * for example, "web" or "play" which would allow the user to chose between a web version or
	 * an Android version.
	 */
	readonly platforms: string[];

	/** Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed" */
	readonly userChoice: Promise<{
		outcome: InstallPromptUserChoice;
		platform: string;
	}>;

	/**
	 * Allows a developer to show the install prompt at a time of their own choosing.
	 * This method returns a Promise.
	 */
	prompt(): Promise<void>;
}

/**
 * State for installprompt
 * Contains the deferred prompt event that can be used to manually display the prompt at a later time and the result of
 * user choice
 */
export interface InstallPromptState {
	deferredPrompt: BeforeInstallPromptEvent;
	choiceResult: InstallPromptUserChoice;
}

// ---------------------------------------------------------------------------
// Loading module
// ---------------------------------------------------------------------------
export interface LoadingState {
	loadingComponents: string[];
	minAnimationDelay: number;
}

export interface LoadingPayload {
	name: string;
	loading: boolean;
}

// ---------------------------------------------------------------------------
// Mediaquery module
// ---------------------------------------------------------------------------
export enum MediaqueryDeviceCategory {
	MOBILE = 'mobile',
	TABLET = 'tablet',
	DESKTOP = 'desktop',
}

export enum MediaqueryDeviceOrientation {
	PORTRAIT = 'portrait',
	LANDSCAPE = 'landscape',
}

export interface MediaqueryDevice {
	category: MediaqueryDeviceCategory;
	orientation: MediaqueryDeviceOrientation;
	/** Standalone means website is installed as a PWA and is displayed without browser navigation */
	isStandalone: boolean;
}

export interface MediaqueryDimensions {
	width: number;
	height: number;
}

export enum Breakpoint {
	MOBILE = 'mobile',
	MOBILE_LANDSCAPE = 'mobile_landscape',
	TABLET = 'tablet',
	TABLET_LANDSCAPE = 'tablet_landscape',
	DESKTOP = 'desktop',
}

export interface MediaqueryState {
	device: MediaqueryDevice;
	dimensions: MediaqueryDimensions;
	currentBreakpoint: Breakpoint;
	lastBreakpoint: Breakpoint;
	desktopBreakpoints: Breakpoint[];
	tabletBreakpoints: Breakpoint[];
	mobileBreakpoints: Breakpoint[];
}

// ---------------------------------------------------------------------------
// Messagebox module
// ---------------------------------------------------------------------------
export enum MessageboxType {
	ERROR = 'error',
	SUCCESS = 'success',
	INFO = 'info',
}

export interface Messagebox {
	global?: boolean;
	isTranslationKey?: boolean;
	key?: string;
	text: string;
	type: MessageboxType;
	dismissible: boolean;
}

export interface MessageboxState {
	messageboxes: Messagebox[];
}

// ---------------------------------------------------------------------------
// OverlayLegacy module
// ---------------------------------------------------------------------------
export interface OverlayItem {
	open: boolean;
	history?: boolean;
}

export interface OverlayState {
	[id: string]: OverlayItem;
}

export interface Size {
	height: number;
	width: number;
}

export interface OverlayRegisterPayload {
	history: boolean;
	id: string;
	open: boolean;
}

// ---------------------------------------------------------------------------
// Products module
// ---------------------------------------------------------------------------
export enum ProductImageType {
	MODEL = 'm-',
	SHADOW = 's-',
	PRODUCT = 'p-',
}

export interface ProductImage extends Image {
	productImageType: ProductImageType;
}

export interface EnhancedProduct extends Product {
	computedProperties: {
		images: {
			gallery: ProductImage[];
			primary: ProductImage;
		};
		inStock: boolean;
		labels: {
			[position: string]: ProductLabel[];
		};
		showSizeSelector: boolean;
		url: string;
	};
}

export interface ProductsState {
	products: {
		[group: string]: {
			[productCode: string]: EnhancedProduct;
		};
	};
}

export interface PointOfServiceState {
	pointsOfService: PointOfService[];
	storeAvailabilityTrusties: Trusty[];
}

// ---------------------------------------------------------------------------
// Routing module
// ---------------------------------------------------------------------------
export enum PageTypes {
	ACCOUNT = 'ACCOUNT',
	BIKE_FINDER = 'BIKE_FINDER',
	BRAND = 'BRAND',
	BRAND_LANDINGPAGE = 'BRAND_LANDINGPAGE',
	BRANDLIST = 'BRANDLIST',
	CART = 'CART',
	CATEGORY = 'CATEGORY',
	CATEGORY_LANDINGPAGE = 'CATEGORY_LANDINGPAGE',
	CONTENT = 'CONTENT',
	CHECKOUT = 'CHECKOUT',
	CHECKOUTCONFIRMATION = 'CHECKOUTCONFIRMATION',
	CHECKOUTLOGIN = 'CHECKOUTLOGIN',
	CHECKOUTREGISTER = 'CHECKOUTREGISTER',
	CLUB_REGISTER_POINT_OF_SALE = 'CLUB_REGISTER_POINT_OF_SALE',
	CLUB_REGISTER_SHOP = 'CLUB_REGISTER_SHOP',
	CLUB_POS_INFO = 'CLUB_POS_INFO',
	CLUB_SMS = 'CLUB_SMS',
	CONTACT = 'CONTACT',
	ERROR = 'ERROR',
	FORGOTTENPASSWORD = 'FORGOTTENPASSWORD',
	HOME = 'HOME',
	LOGIN = 'LOGIN',
	MYACCOUNT = 'MYACCOUNT',
	NEWSLETTER = 'NEWSLETTER',
	NEWSLETTER_GLOBAL_UNSUBSCRIBE = 'NEWSLETTER_GLOBAL_UNSUBSCRIBE',
	NEWSLETTER_UNSUBSCRIBE = 'NEWSLETTER_UNSUBSCRIBE',
	NEWSLETTER_UNSUBSCRIBE_CLUB = 'NEWSLETTER_UNSUBSCRIBE_CLUB',
	ORDERCONFIRMATION = 'ORDERCONFIRMATION',
	PASSWORD_RESET = 'PASSWORD_RESET',
	PRODUCT = 'PRODUCT',
	PRODUCTSEARCH = 'PRODUCTSEARCH',
	PRODUCT_RELEASE_CALENDAR = 'PRODUCT_RELEASE_CALENDAR',
	REGISTER = 'REGISTER',
	STORE = 'STORE',
	STORELOCATOR = 'STORELOCATOR',
}

export interface RoutingState {
	allowRouting: boolean;
	pageType: PageTypes;
	spaData: {
		datalayer: SpaDigitalDatum;
		links: SpaLink[];
		meta: SpaMetaInformation[];
		richSnippet: string;
		title: string;
	};
	scrollTargetAfterRouting?: string | { x: number; y: number };
}

// ---------------------------------------------------------------------------
// Search module
// ---------------------------------------------------------------------------
export interface CategoryList {
	[code: string]: {
		code: string;
		name: string;
		landingPage: boolean;
		path: string;
		image?: Image;
	};
}

export interface SearchResponse {
	breadcrumbs?: Breadcrumb[];
	brandCategoryCode?: string;
	campaignRedirectDestination?: string;
	campaign?: Campaign[];
	categoryCode?: string;
	categoryHeadline?: string;
	categoryName?: string;
	currentNavigation?: CurrentNavigation;
	didYouMean?: string;
	facets?: SearchFacet[];
	pagination?: SearchPagination;
	seoHeadline?: string;
	seoImage?: string;
	seoPosition?: string;
	seoText?: string;
	sorts?: SearchSorts;
	suggestedSearchGendersLinks?: MapEntry[];
	text?: string;
	timestamp: number;
	showSwissBillingMessage?: boolean;
}

// SearchResultSimple differs from SearchResult (from RAML) having only product code, not whole object
export interface SearchResultSimple extends ListingItem {
	origPos?: number;
	pos?: number;
	productCode: string;
}

export interface SearchStateRecoveryData {
	categoryCode: string;
	productCode: string;
	listedProductCodes: string[];
	pageNumber: number;
}

export interface SearchState {
	categories: CategoryList;
	campaign?: Campaign[];
	response: SearchResponse;
	headline: string;
	results?: SearchResultSimple[][];
	searchInProgress: boolean;
	showError: boolean;
	lastSelection?: SearchStateRecoveryData;
	requestFacets?: SearchFacet[];
}

// ---------------------------------------------------------------------------
// Server context module
// ---------------------------------------------------------------------------
export type UserAgentDeviceCategory = 'mobile' | 'tablet' | 'desktop';

export interface UserAgent {
	deviceCategory?: UserAgentDeviceCategory;
	isBot?: boolean;
	isRetina?: boolean;
	resolution?: Breakpoint;
}

export interface HybrisSession {
	clsOptimizationEnabled: boolean;
	csrfToken?: string;
	cookies: string;
	debugEnabled: boolean;
	dynamicYieldAsync: boolean;
	dynamicYieldSectionId: string;
	dynamicYieldApiToken: string;
	googleTagManagerContainerId: string;
	googleTagManagerEnabled: boolean;
	clientIPAddress: string;
	language: string;
	trackingEnabled: boolean;
	smartProxyEnabled: boolean;
}

export interface ServerContextEnv {
	DEBUG: string;
	tracking: {
		performance: {
			active: boolean;
			debug: boolean;
		};
		cls: {
			active: boolean;
			thresholds: {
				total: number;
				page: number;
				item: number;
			};
		};
		longTasks: {
			active: boolean;
			tti: boolean;
		};
		apis: {
			active: boolean;
		};
		spaRumThresholdReporting: {
			active: boolean;
			thresholds: {
				lcp?: number;
				fcp?: number;
				inp?: number;
				fid?: number;
				tbt?: number;
				tti?: number;
			};
		};
	};
	timeouts: {
		apiRequestExternalSsr: number;
		apiRequestExternalClient: number;
	};
}

export interface ServerContextState {
	baseURL: string;
	env: ServerContextEnv;
	userAgent: UserAgent;
	session: HybrisSession;
}

// ---------------------------------------------------------------------------
// Server settings module
// ---------------------------------------------------------------------------

export interface StructuredPromotionTypeSettings {
	[code: string]: PromotionTypeSettings;
}

export interface ServerSettingsState {
	settings: Serversetting;
	promotionTypeSettings: StructuredPromotionTypeSettings;
}

// ---------------------------------------------------------------------------
// Tracking module
// ---------------------------------------------------------------------------
export enum PerformanceEvent {
	appRender = 'APP_RENDER',
	appLoading = 'APP_LOADING',
	apiRequest = 'API_REQUEST',
	apiRequestAbort = 'API_REQUEST_ABORT',
	nuxtReady = 'NUXT_READY',
	data = 'DATA',
}

export interface PerformanceTrackingMark {
	event: string;
	times: { start: number; end?: number };
	uniqueId?: string;
	data?: any;
	duration?: number;
}

export interface TrackingState {
	performance: {
		marks: PerformanceTrackingMark[];
		reportedEvents: {
			load: boolean;
			render: boolean;
			clsControlFinished: boolean;
			allMarksFinished: boolean;
		};
		readyToSend: boolean;
		unresolvedMarks: number;
	};
}

// ---------------------------------------------------------------------------
// User module
// ---------------------------------------------------------------------------
export interface UserData extends User {
	loaded: boolean;
}

export interface UserActionResponse
	extends LoginResponse,
		RegistrationResponse,
		GuestRegistrationResponse,
		ForgottenPaswordResponse,
		ValidationErrorResponse {}

export enum UserActionType {
	FORGOTTEN_PASSWORD = 'forgottenPassword',
	GUEST_REGISTRATION = 'guestRegistration',
	LOGIN = 'login',
	REGISTRATION = 'registration',
	REGISTRATION_CHECKOUT = 'registration_checkout',
}

export interface UserAction {
	details: UserActionResponse;
	type: UserActionType;
}

export interface UserState {
	user: UserData;
	response: UserAction;
}

export interface UxState {
	nuxtReady: boolean;
	userInteracted: boolean;
	cmpUserChoiceExists: boolean;
	pageSwitchInProgress: boolean;
	isPageSwitchHookActive: boolean;
	displayFooter: boolean;
}

export interface LoginData {
	email: string;
	password: string;
}

// ---------------------------------------------------------------------------
// Voucher module
// ---------------------------------------------------------------------------
export interface Voucher {
	code: string;
	amount?: number;
	formattedAmount?: string;
	name?: string;
}

export interface VoucherState {
	message: string;
	vouchers: Voucher[];
	currentInput: Voucher;
	userGiftBonusCards: CustomerGiftCard[];
	userGiftBonusCardTotals: ProductPrice;
}

export interface GiftCard extends Voucher {
	pin: string;
}

export interface UnsavedSearchFacet extends SearchFacet {
	changed?: number;
}

export interface UnsavedSearchState {
	facets: {
		[code: string]: UnsavedSearchFacet;
	};
}

export interface SearchSuggestionState {
	requestedSuggestions: number;
	response: Autocomplete;
}

export interface TrackingSearchFacet extends SearchFacet {
	position: number;
}

export interface TrackingSearchState {
	changedFacets: TrackingSearchFacet[];
}

export interface FactFinderTrackingEvent {
	origPageSize: number;
	origPos: number;
	page: number;
	pos: number;
	productId: string;
	query: string;
}

export interface ClsState {
	config: {
		optimizationActive: boolean;
		allowFallback: boolean;
		fallbackTimerMs: number;
		fallbackTimerMsSpa: number;
		visibilityCheck: boolean;
		debugging: DebugSettings;
	};
	resetStateAllTrigger: boolean;
	activateStateAllTrigger: boolean;
	ssrRegister: { [key: string]: { [key: string]: boolean } };
	messages: any[];
}

export enum ClubStep {
	INITIALIZATION = 'INITIALIZATION',
	MATCHED = 'MATCHED',
	NO_MATCHED = 'NO_MATCHED',
	ADD_NUMBER = 'ADD_NUMBER',
	CONFIRMATION = 'CONFIRMATION',
	FORGOTTEN_NUMBER = 'FORGOTTEN_NUMBER',
}

export interface ClubProcess {
	clubDiscount: ProductPrice;
	clubId: string;
	currentStep: ClubStep;
	email: string;
	enabled: boolean;
	label: ProductLabel;
	messages: GlobalMessage[];
	new: boolean;
}

export interface ClubState {
	process: ClubProcess;
	status: ClubInfo;
}

// --------------------------------------------------------------------------
// Common
// --------------------------------------------------------------------------
export interface CmsContentEntryReference {
	key: string;

	[additionalProps: string]: any;
}

export enum CmsLabelType {
	BLACK = 'BLACK',
	BLUE = 'BLUE',
	GREEN = 'GREEN',
	RED = 'RED',
	YELLOW = 'YELLOW',
}

export enum CmsLabelTheme {
	BLACK = 'theme_label_black',
	BLUE = 'theme_label_blue',
	GREEN = 'theme_label_green',
	RED = 'theme_label_red',
	YELLOW = 'theme_label_yellow',
}

export interface CmsLabel {
	text: string;
	type: CmsLabelType;
}

export enum CmsDevices {
	MOBILE = 'MOBILE',
	DESKTOP = 'DESKTOP',
}

export enum CmsTeaserButtonLinkTarget {
	SAMEWINDOW = 'SAMEWINDOW',
	NEWWINDOW = 'NEWWINDOW',
}

export enum CmsTeaserPosition {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
}

export interface CmsTeaserButtonData {
	text: string;
	url: string;
	target: CmsTeaserButtonLinkTarget;
	params: any;
}

export interface AspectRatio {
	width: number;
	height: number;
}

export interface CmsAbstractTeaserData {
	code: string;
	devices: CmsDevices[];
	label: CmsLabel;
	button: CmsContentEntryReference;
	position: CmsTeaserPosition;
	aspectRatio: AspectRatio;
}

// --------------------------------------------------------------------------
// Text-picture Teaser
// --------------------------------------------------------------------------
export enum CmsTextPictureTeaserLayout {
	HEADLINE = 'HEADLINE',
	HEADLINE_BOTTOM = 'HEADLINE_BOTTOM',
	FREE_TEXT = 'FREE_TEXT',
}

export interface CmsTextPictureTeaserImage {
	alt?: string;
	assetid: string;
	url?: string;
	isCloudinaryAsset: boolean;
}

export interface CmsTextPictureTeaserData extends CmsAbstractTeaserData {
	brand: CmsTextPictureTeaserImage;
	layout: CmsTextPictureTeaserLayout;
	picture: CmsTextPictureTeaserImage;
	preTitle?: string;
	text: string;
	title: string;
}

// --------------------------------------------------------------------------
// Product Teaser
// --------------------------------------------------------------------------
export interface CmsProductTeaserData extends CmsAbstractTeaserData {
	description: string;
	disclaimer: string;
	product: CmsContentEntryReference;
	shortages: CmsContentEntryReference[];
}

export interface CmsAbstractTextData {
	devices: CmsDevices[];
}

// --------------------------------------------------------------------------
// Text
// --------------------------------------------------------------------------
export interface CmsTextData extends CmsAbstractTextData {
	text: string;
}

export enum CmsTeaserTheme {
	TINY = 'theme_tiny',
	SMALL = 'theme_small',
	MEDIUM = 'theme_medium',
	LARGE = 'theme_large',
}

export enum CmsTextTheme {
	DEFAULT = 'default',
}

// --------------------------------------------------------------------------
// Grid Layout
// --------------------------------------------------------------------------
export const CmsGridLayoutComponentName = 'GridLayoutComponent';

export enum CmsGridLayoutRowLayout {
	TEXT = 'TEXT',
	PERCENT_100 = 'PERCENT_100',
	PERCENT_50 = 'PERCENT_50',
	PERCENT_33 = 'PERCENT_33',
	PERCENT_25 = 'PERCENT_25',
}

export interface CmsGridLayoutRow {
	layout: CmsGridLayoutRowLayout;
}

export interface CmsGridLayoutComponent {
	code: string;
	datas: CmsContentEntryReference[];
	rows: CmsGridLayoutRow[];
}

export type CmsBackendContentEntry =
	| Bar
	| BrandTeaserComponent
	| CmsAbstractTeaserData
	| CmsGridLayoutComponent
	| CmsProductData
	| CmsProductTeaserData
	| CmsTeaserButtonData
	| CmsTeaserExpiringShortageData
	| CmsTextPictureTeaserData
	| CampaignTeaserComponent
	| DYProductRecommendationComponent
	| GridLayout
	| ImageTeaserComponent
	| PromotionTeaserComponent
	| TeaserDataList
	| TestimonialTeaserComponent
	| TextComponent;

export type CmsContentEntry =
	| Bar
	| BrandListModel
	| CampaignTeaserModel
	| CategorySliderComponent
	| CmsAbstractTeaserData
	| CmsGridLayoutComponent
	| CmsProductData
	| CmsProductTeaserData
	| CmsTeaserButtonData
	| CmsTeaserExpiringShortageData
	| CmsTextPictureTeaserData
	| DYProductRecommendationModel
	| GridLayout
	| ImageTileModel
	| ImageTileWithDescriptionModel
	| PromotionTeaserModel
	| RichTextModel
	| SectionLayoutDataListModel
	| SectionLayoutModel
	| SliderModel
	| TestimonialModel
	| UspBarModel;

// ---------------------------------------------------------------------------
// CMScontent module
// ---------------------------------------------------------------------------
export interface CmsDereferencedEntry {
	id: string;
	component: string;
	content: CmsContentEntry;
}

export interface CmsContentState {
	contentGender: Gender;
	references: {
		[componentName: string]: CmsBackendContentEntry;
	};
	slots: {
		[slotName: string]: CmsContentEntryReference[];
	};
}

// ---------------------------------------------------------------------------
// Brand module
// ---------------------------------------------------------------------------
export interface BrandState {
	brands: Brand[];
}

// ---------------------------------------------------------------------------
// MyAccount module
// ---------------------------------------------------------------------------
export interface MyAccountState {
	navigationItems: ContentNavigationNode[];
	orders: Order[];
}

// ---------------------------------------------------------------------------
// CommunicationCenter module
// ---------------------------------------------------------------------------
export interface CommunicationCenterEntry {
	id: string;
	enabled?: boolean;
	value?: string;
}

export type CommunicationCenterValues = Record<string, boolean | string>;

export interface CommunicationCenterState extends CommunicationCenter {
	values: CommunicationCenterValues;
}

// ---------------------------------------------------------------------------
// RootState
// ---------------------------------------------------------------------------
export interface RootState {
	brand: BrandState;
	cart: CartState;
	cls: ClsState;
	club: ClubState;
	checkout: CheckoutModuleState;
	cmscontent: CmsContentState;
	communicationCenter: CommunicationCenterState;
	dynamicyield: DynamicYieldState;
	forms: FormsState;
	i18n: I18NModuleState;
	installprompt: InstallPromptState;
	loading: LoadingState;
	mediaquery: MediaqueryState;
	messagebox: MessageboxState;
	pointofservice: PointOfServiceState;
	products: ProductsState;
	overlay: OverlayState;
	routing: RoutingState;
	search: SearchState;
	search_unsaved: UnsavedSearchState;
	servercontext: ServerContextState;
	serversettings: ServerSettingsState;
	user: UserState;
	user_addressbook: AddressBookState;
	ux: UxState;
	voucher: VoucherState;
	myaccount: MyAccountState;
}

// ---------------------------------------------------------------------------
// ProductTile module
// ---------------------------------------------------------------------------

export interface ProductTile {
	uid: string;
	additionalClickListener?: Function;
	preload?: boolean;
	productCode: string;
	storeGroup: string;
	showThumbsOnHover?: boolean;
	previewImageSettings?: ImageOptimizationSettings;
	skipClsControl?: boolean;
	variant?: string;
}
