import type { Store, Module } from 'vuex';
import { runTaskWithPromise } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { RootState } from '~/@api/store.types';

interface UseModule<STATE, API> {
	state: STATE;
	api: API;
}

type ApiConstructor<API> = (store: Store<RootState>) => API;

export function useStoreModule<STATE, API>(
	name: string,
	store: Store<RootState>,
	module: Module<STATE, RootState>,
	api: ApiConstructor<API>,
	isRetry?: boolean,
): UseModule<STATE, API> {
	if (process.server) {
		return createUseModule(name, store, module, api);
	}

	const useStoreFunctions = getUseStoreFunctions();

	// If requested module is 'null', it is already in initialization progress - loop/wait for 1 millisecond
	if (useStoreFunctions[name] === null && !isRetry) {
		return useStoreModule(name, store, module, api, true);
	}

	if (!useStoreFunctions[name]) {
		// Synchronous registration of store module
		useStoreFunctions[name] = null;
		useStoreFunctions[name] = createUseModule(name, store, module, api);
	}

	return useStoreFunctions[name];
}

export async function clientInitStoreModule<STATE, API>(
	name: string,
	store: Store<RootState>,
	module: Module<STATE, RootState>,
	api: ApiConstructor<API>,
	isRetry?: boolean,
): Promise<void> {
	if (process.server) {
		return Promise.resolve();
	}

	const useStoreFunctions = getUseStoreFunctions();

	if (useStoreFunctions[name] === null && !isRetry) {
		return Promise.resolve(clientInitStoreModule(name, store, module, api, true));
	}

	useStoreFunctions[name] = useStoreFunctions[name] ?? null;

	if (!useStoreFunctions[name]) {
		// Asynchronous registration of store module
		await runTaskWithPromise(async () => {
			useStoreFunctions[name] = createUseModule(name, store, module, api);

			return await Promise.resolve();
		});
	}

	return Promise.resolve();
}

function getUseStoreFunctions<STATE, API>(): UseModule<STATE, API> {
	if (!(window as any).osp?.useStoreFunctions) {
		(window as any).osp = {
			...(window as any).osp,
			useStoreFunctions: {
				...(window as any).osp?.useStoreFunctions,
			},
		};
	}

	return (window as any).osp?.useStoreFunctions;
}

function createUseModule<STATE, API>(
	name: string,
	store: Store<RootState>,
	module: Module<STATE, RootState>,
	api: ApiConstructor<API>,
): UseModule<STATE, API> {
	if (!store.hasModule(name)) {
		store.registerModule(name, { namespaced: true, ...module });
	}

	return {
		api: api(store),
		state: store.state[name],
	};
}
