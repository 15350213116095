import { Context } from '@nuxt/types';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { getPerformanceTrackingConfig } from '~/app-utils/tracking.utils';
import { OspNuxtRuntimeConfig } from '~/@types/OspNuxtRuntimeConfig';
import { importLongTasksTracker } from '~/app-utils/dynamic-imports';

async function PluginIntegration(context: Context) {
	const trackingConfig = getPerformanceTrackingConfig(context.$config as OspNuxtRuntimeConfig);

	if (!trackingConfig.longTasks.active && !trackingConfig.longTasks.tti) {
		return;
	}

	// Only import LongTasksTracker code if tracking is activated
	const { LongTasksTracker } = await importLongTasksTracker();
	LongTasksTracker.observeLongTasks(trackingConfig.longTasks);
}

// Do not defer this plugin until Nuxt is ready, to watch for occurring long tasks right from the beginning
export default function (context: Context) {
	runTask(() => {
		PluginIntegration(context);
	});
}
