import _isString from 'lodash-es/isString';
import { ActionContext } from 'vuex';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { PromotionTypeSettings, Serversetting } from '@/generated/hybris-raml-api';
import {
	ServerSettingsState,
	RootState,
	StructuredPromotionTypeSettings,
} from '~/@api/store.types';
import {
	mapFn,
	SERVERSETTINGS_M_SAVE_SETTINGS,
	SERVERSETTINGS_A_UPDATE_SETTINGS,
} from '~/@constants/store';
import { getJson } from '~/app-utils/http';
import { backend } from '~/@api/backend';

// Initial state -----------------------------------------------------------------------------------

const state = (): ServerSettingsState => ({
	settings: null,
	promotionTypeSettings: null,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(SERVERSETTINGS_M_SAVE_SETTINGS)](_state: ServerSettingsState, payload: Serversetting) {
		_state.settings = { ..._state.settings, ...payload };

		// Strip trailing "/" from Scene7 url
		if (_state.settings?.scene7ImageUrl?.slice(-1) === '/') {
			_state.settings.scene7ImageUrl = _state.settings.scene7ImageUrl.slice(0, -1);
		}

		// Strip trailing "/" from Cloudinary url
		if (_state.settings?.cloudinaryImageUrl?.slice(-1) === '/') {
			_state.settings.cloudinaryImageUrl = _state.settings.cloudinaryImageUrl.slice(0, -1);
		}

		// Make sure the given comma separated string is transferred into an array
		if (
			_state.settings?.giftcard?.cardIdPrefix &&
			_isString(_state.settings?.giftcard?.cardIdPrefix)
		) {
			_state.settings.giftcard.cardIdPrefix = _state.settings.giftcard.cardIdPrefix.split(',');
		}

		_state.promotionTypeSettings = payload.promotionTypes?.reduce(
			(prev: StructuredPromotionTypeSettings, current: PromotionTypeSettings) => ({
				...prev,
				[current.code]: current,
			}),
			{},
		);
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(SERVERSETTINGS_A_UPDATE_SETTINGS)](
		context: ActionContext<ServerSettingsState, RootState>,
	) {
		try {
			const response = await getJson(backend.API.V2.SERVER_SETTINGS(this), this);
			const settings = response.json || null;

			if (settings) {
				context.commit(mapFn(SERVERSETTINGS_M_SAVE_SETTINGS), settings);
			}
		} catch (error) {
			Logger.error('Could not fetch Settings API', error);
		}

		elapsedTime(SERVERSETTINGS_A_UPDATE_SETTINGS);
	},
};

export default {
	state,
	mutations,
	actions,
};
