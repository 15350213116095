import type { Store } from 'vuex';
import slugify from '@/node_modules/@osp/utils/src/slugify';
import { MediaSource } from '~/node_modules/@osp/design-system/types/media';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useServerSettingsStore } from '~/@api/store/serverSettingsApi';
import { RootState } from '~/@api/store.types';
import { Image } from '~/generated/hybris-raml-api';

export interface ImageOptions {
	width?: number;
	height?: number;
	path?: string;
	preset?: string;
	seoText?: string;
	format?: string;
	square?: boolean;
	ignoreRetina?: boolean;
}

export const calculateImageUrl = (
	store: Store<RootState>,
	image: Image,
	options: ImageOptions,
): string => {
	return image.isCloudinaryAsset
		? buildCloudinaryUrl(store, image, options)
		: buildScene7ImageUrl(store, image, options);
};

export const createImageSources = (
	store: Store<RootState>,
	image: Image,
	breakpoints: { [breakpoint: string]: number },
	options: ImageOptions,
): MediaSource[] => {
	return Object.keys(breakpoints)
		.sort((a, b) => Number(b) - Number(a))
		.map((breakpoint) => ({
			media: `(min-width: ${breakpoint}px)`,
			srcset: calculateImageUrl(store, image, {
				...options,
				width: breakpoints[breakpoint],
			}),
		}));
};

function buildCloudinaryUrl(store: Store<RootState>, image: Image, options: ImageOptions): string {
	const { state: serverSettingsState } = useServerSettingsStore(store);
	const { state: serverContextState } = useServerContextStore(store);
	const url = [serverSettingsState.settings.cloudinaryImageUrl];

	if (options.preset) {
		url.push('t_' + options.preset);
	}

	if (options.width) {
		url.push('w_' + options.width);
	}

	if (options.height && !options.square) {
		url.push('h_' + options.height);
	} else if (options.width && options.square) {
		url.push('h_' + options.width);
	}

	if (serverContextState.userAgent.isRetina && !options.ignoreRetina) {
		url.push('dpr_2.0');
	}

	url.push(
		image.assetId,
		slugify(options.seoText || image.altText || 'image', false),
	);

	return url.join('/') + '.' + (options.format || 'jpg');
}

function buildScene7ImageUrl(store: Store<RootState>, image: Image, options: ImageOptions): string {
	const { state: serverSettingsState } = useServerSettingsStore(store);
	const { state: serverContextState } = useServerContextStore(store);
	const isRetina = serverContextState.userAgent.isRetina && !options.ignoreRetina;
	const displayFactor = isRetina ? 2 : 1;
	const width = (options.width || 0) * displayFactor;
	const height = (options.height || 0) * displayFactor;

	return (
		serverSettingsState.settings.scene7ImageUrl +
		`/${options.path || 'int'}` +
		// Set the preset name or the default preset if non given.
		`/preset/${options.preset || 'image'}` +
		// On retina devices, add the "-hd" postfix to the preset
		(isRetina ? '-hd' : '') +
		// Add optional settings
		(width ? `/w${width}` : '') +
		(height && !options.square ? `/h${height}` : '') +
		(options.square ? `/h${width}` : '') +
		`/fit/fit-1` +
		// Add optional seoText
		(options.seoText ? `/${slugify(options.seoText, false)}--` : '/') +
		// Add assetId and format
		`${image.assetId}.${options.format || 'jpg'}`
	).replace(/ /g, '%20');
}
