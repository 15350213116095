import { Context } from '@nuxt/types';
import type { Route } from 'vue-router';
import { PageTypes } from '~/@api/store.types';
import { regularPageview } from '~/tracking/events/regularPageview';

export default ({ app, $gtm }: Context) => {
	app.router.afterEach((to, from) => {
		if (isCartPage(to)) return;

		// Defer this a little, as it should not be be
		// the first in line to run on cmp 'consent'
		setTimeout(() => {
			regularPageview($gtm, from.fullPath, to.fullPath);
		}, 100);
	});
};

// Do not fire a pageview event when entering the cart page.
// The cart page fires a pageview by itself within the beforeRouteEnter component hook
function isCartPage(route: Route) {
	return Array.isArray(route.meta)
		? route.meta.find((entry) => entry.pageType === PageTypes.CART)
		: route.meta.pageType === PageTypes.CART;
}
